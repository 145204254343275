import React, {useState, useEffect} from "react";
import axios from "axios";
import BTService from "../../assets/bhutan.png";
import RGCF from "../../assets/apartment.png";
import Software from "../../assets/coding_2010990.png";
import DM from "../../assets/data-management.png";
import TMP from "../../assets/file.png";
import Applications from "../../assets/test.png";
import TestSetUp from "../../assets/web-settings-confirmed_74892.png";
import Home from "../../assets/find.png";
import BTT from "../../assets/verified-user_9957231.png";
import Container from "../../assets/container.png";
import Orphaned from "../../assets/computer_15689006.png";
import {useNavigate} from "react-router-dom";

export const SoftwareFolderBar: React.FC = () => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const navigate = useNavigate();

  const iconMapping: any = {
    "BT Services": BTService,
    "Residential Gateway Core Functions": RGCF,
    "BT Smart Framework": BTService,
    "System Software": Software,
    Deprecated_tests: TestSetUp,
    "Device Management": DM,
    Temp: TMP,
    Applications: Applications,
    Homeflow: Home,
    HomeView: Home,
    "Test Cases Belfast Team": BTT,
    Containerisation: Container,
    default: Orphaned,
  };

  const token = sessionStorage.getItem("Access");

  useEffect(() => {
    const headers = {
      Authorization: token,
      Accept: "*/*",
    };

    axios
      .get("https://rwq202prpl.execute-api.eu-west-1.amazonaws.com/sea/folders/9164", {headers})
      .then(response => {
        const items = response.data.data.map((item: any) => ({
          label: item.name.replace(/[0-9]\) | \(x\)|x\)/g, ""),
          icon:
            iconMapping[item.name.replace(/[0-9]\) | \(x\)|x\)/g, "")] ||
            iconMapping.default,
        }));
        setSidebarItems(items);
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      <div className="table-container">
        <table className="w-full">
          <thead>
            <tr className="underline underline-offset-8">
              <th className="text-left">Folder Name</th>
              <th className="p-2.5 xl:p-5 text-center">Passed</th>
              <th className="p-2.5 xl:p-5 text-center">Failed</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {sidebarItems.map((item: any, index) => (
              <tr className="border-b border-[#eee]" key={index}>
                &nbsp;
                <td className="flex items-center">
                  <img src={item.icon} alt={item.label} />
                  &nbsp;
                  <span className="cursor-pointer">
                    {/* // onClick={() => {
                    //   if (item.label === "BT Services") {
                    //     navigate("/pipelineview/test-setup/");
                    //   } else if (item.label === "Software") {
                    //     navigate("/pipelineview/software/");
                    //   }
                    // }}> */}
                    {item.label}
                  </span>
                </td>
                <td className="p-2.5 xl:p-5 text-center">{0}</td>
                <td className="p-2.5 xl:p-5 text-center">{0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

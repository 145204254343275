import React, {useState} from "react";
import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import PipelineView from "./Pages/PipeLineView";
import Login from "./Pages/Login";
import ProtectedRoutes from "./CustomHooks/ProtectedRoute";
import TestSetUp from "./Pages/TestSetUp";
import Software from "./Pages/Software";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/pipelineview"
              element={<PipelineView isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/test-setup"
              element={<TestSetUp isAuthenticated={isAuthenticated} />}
            />
            <Route
              path="/pipelineview/software"
              element={<Software isAuthenticated={isAuthenticated} />}
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React from "react";

interface CardDataStatsProps {
  title: string;
  total: number;
  rate: number;
  levelUp?: boolean;
  levelDown?: boolean;
  icon: string;
  cardIcon: string;
  onClick?: () => void;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
  title,
  total,
  rate,
  levelUp,
  levelDown,
  icon,
  cardIcon,
  onClick,
}) => {
  return (
    <div className="rounded-lg border border-stroke bg-white py-7 px-7 shadow-lg dark:border-strokedark dark:bg-boxdark">
      <div className="flex h-11.5 w-11.5 items-right rounded-full">
        {" "}
        <img width="40" height="40" src={cardIcon} alt="Card-Icon" />
      </div>
      <div className="mt-4 flex items-end justify-between">
        <div>
          <h4 className="text-title-md font-bold text-black">{total}</h4>
          <span
            className={`${title === "TCS ▲" ? "cursor-pointer" : title === "TCS ▼" ? "cursor-pointer" : ""} text-sm font-medium text-gray-500`}
            onClick={onClick}>
            {title}
          </span>
        </div>
        <span
          className={`flex m-0.5 items-center gap-1 text-sm font-medium ${
            levelUp && "text-green-600"
          } ${levelDown && "text-red-600"} `}>
          {rate}%
          {levelUp && <img width="20" height="20" src={icon} alt="arrow-up" />}
          {levelDown && (
            <img width="20" height="20" src={icon} alt="arrow-down" />
          )}
        </span>
      </div>
    </div>
  );
};

export default CardDataStats;

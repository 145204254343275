import React, {useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import PipelineView from "../Pages/PipeLineView";
import TestSetUp from "../Pages/TestSetUp";
import Software from "../Pages/Software";

interface IProtectedRoute {
  isAuthenticated: boolean;
}

const ProtectedRoutes: React.FC<IProtectedRoute> = ({isAuthenticated}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to login page if user is not authenticated
    if (
      !isAuthenticated &&
      (location.pathname === "/dashboard" ||
        location.pathname === "/pipelineview" ||
        location.pathname === "/pipelineview/test-setup" ||
        location.pathname === "/pipelineview/software")
    ) {
      navigate("/");
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/pipelineview"
        element={<PipelineView isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/test-setup"
        element={<TestSetUp isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/pipelineview/software"
        element={<Software isAuthenticated={isAuthenticated} />}
      />
    </Routes>
  );
};

export default ProtectedRoutes;

import React from "react";

interface ViewProps {
  title: string;
  description: string;
  handleOnClick: () => void;
}

export const View: React.FC<ViewProps> = ({
  title,
  description,
  handleOnClick,
}) => {
  return (
    <div className="bg-gray-100 rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
      <div className="bg-gray-800 rounded-full w-12 h-12 flex items-center justify-center mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-gray-100"
          viewBox="0 0 20 20"
          fill="currentColor">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">{title}</h2>
      <p className="text-gray-700 text-base">{description}</p>
      <div className="mt-6">
        <button
          className="bg-gray-800 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline"
          onClick={handleOnClick}>
          View
        </button>
      </div>
    </div>
  );
};

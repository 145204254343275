import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {Sidebar} from "../Libraries/PipeLineView/Sidebar";
import {CardComponent} from "../Libraries/TestSetUpCardComponent/CardComponent";
import Navbar from "../Libraries/Navbar";
import {
  useNavigateToDashboard,
  useNavigateToPipelineView,
} from "../CustomHooks/NavigationHooks";
import TestSetUpTable from "../Table/TestSetUpTable";

interface Props {
  isAuthenticated: boolean;
}

const TestSetUp: React.FC<Props> = ({isAuthenticated}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const {handleNavigateToDashboard} = useNavigateToDashboard(
    isAuthenticated,
    location.pathname,
  );

  const {handleNavigateToPipelineView} = useNavigateToPipelineView(
    isAuthenticated,
    location.pathname,
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const token = sessionStorage.getItem("Access");

  const dashboardUrl = `/dashboard/#access_token=${token}`;

  const pipelineviewUrl = `/pipelineview/`;

  return (
    <div>
      <Navbar isOpen={isOpen} onToggle={handleToggle} />
      <div className="flex h-screen w-[120vw] p-8">
        {/* <Sidebar /> */}
        <div className="w-4/5 bg-white -p-[0.5rem]">
          <div className="flex items-center text-gray-500 text-sm">
            <a
              href={dashboardUrl}
              className="mr-2 hover:text-gray-700"
              onClick={handleNavigateToDashboard}>
              Home
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href={pipelineviewUrl}
              className={`mr-2 hover:text-gray-700 ${
                location.pathname === "/pipelineview/"
                  ? "font-bold text-black"
                  : "text-gray-700"
              }`}
              onClick={handleNavigateToPipelineView}>
              Pipeline View
            </a>
            <span className="mr-2">{">"}</span>
            <a
              href="/"
              className={`mr-2 hover:text-gray-700 ${
                location.pathname === "/pipelineview/test-setup/"
                  ? "font-bold text-black"
                  : "text-gray-700"
              }`}>
              Test Setup
            </a>
          </div>
          <br />
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Summary</h2>
          </div>
          <CardComponent />
          <br />
          <TestSetUpTable />
        </div>
      </div>
    </div>
  );
};

export default TestSetUp;

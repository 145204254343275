// import React from "react";

// /**
//  * AutoLogoutPopup Component
//  *
//  * This React component displays a popup message to inform the user that their session
//  * has expired, and they have been logged out. It provides a button for the user to log
//  * in again. The `onConfirm` prop is a callback function that should be triggered when
//  * the user clicks the "Login" button.
//  *
//  * @param {object} props - The component props.
//  * @param {function} props.onConfirm - Callback function to handle the login button click.
//  *
//  * @returns {JSX.Element} - Returns the JSX element for the auto logout popup.
//  */

// interface AutoLogoutPopupProps {
//   onConfirm: () => void;
// }

// const AutoLogoutPopup: React.FC<AutoLogoutPopupProps> = ({onConfirm}) => {
//   return (
//     <div className="popup ">
//       <div className="mt-[24rem]">
//         <h1 className="text-center text-5xl">
//           Your session has expired. You have been logged out.
//         </h1>
//         <br />
//         <br />
//         <h1 className="text-center text-2xl">
//           Please click the button below to log in again
//         </h1>
//         <br />
//         <br />
//         <button
//           className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2 ml-[48rem] md:ml-[22rem] lg:ml-[28rem] sm:ml-[14rem] xl:ml-[48rem] 2xl:ml-[48rem]"
//           onClick={onConfirm}>
//           Login
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AutoLogoutPopup;
import React from "react";

interface AutoLogoutPopupProps {
  onConfirm: () => void;
}

const AutoLogoutPopup: React.FC<AutoLogoutPopupProps> = ({onConfirm}) => {
  return (
    <div className="popup container mx-auto p-4">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-5xl text-center mb-4">
          Your session has expired. You have been logged out.
        </h1>
        <p className="text-2xl text-center mb-8">
          Please click the button below to log in again
        </p>
        <button
          className="bg-black text-white py-2 px-4 rounded-md cursor-pointer"
          onClick={onConfirm}>
          Login
        </button>
      </div>
    </div>
  );
};

export default AutoLogoutPopup;

import React, {useEffect, useState} from "react";
import axios from "axios";

const SoftwareTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [filteredData, setFilteredData] = useState([]);
  const [jiraLink, setJiraLink] = useState([]);
  const [redirectLink, setRedirectLink] = useState("");
  const [summaryData, setSummaryData]: any = useState({
    pass_count: 0,
    fail_count: 0,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const token = sessionStorage.getItem("Access");

  const fetchData = async () => {
    try {
      const headers = {
        Authorization: token,
        Accept: "*/*",
      };
      const response = await axios.get(
        "https://rwq202prpl.execute-api.eu-west-1.amazonaws.com/sea/folders/9164/issues",
        {headers},
      );
      const apiData = response.data.data.issues;
      const tableData = apiData.map((item: any) => {
        return {
          key: item.key,
          self: item.data.self,
          summary: item.data.fields.summary,
          description: item.data.fields.issuetype.description,
          closedDescripiton: item.data.fields.status.description,
          statusCategory: item.data.fields.status.statusCategory.name,
          priority: item.data.fields.priority.name,
          id: item.data.id,
        };
      });
      setFilteredData(tableData);
      setJiraLink(apiData.map((item: {key: {key: any}}) => item.key));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSummaryData = async () => {
    try {
      const headers = {
        Authorization: token,
        Accept: "*/*",
      };
      const response = await axios.get(
        "https://rwq202prpl.execute-api.eu-west-1.amazonaws.com/sea/tests/summary/9164",
        {headers},
      );
      const summaryData = response.data.key_data;
      setSummaryData(summaryData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    void fetchData();
    void fetchSummaryData();
  }, []);

  // Paginate the filtered test results
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderPagination = () => {
    const buttons = [];
    const maxButtonsToShow = 5;
    const halfButtonsToShow = Math.floor(maxButtonsToShow / 2);

    let startPage = currentPage - halfButtonsToShow;
    let endPage = currentPage + halfButtonsToShow;

    if (startPage <= 0) {
      startPage = 1;
      endPage = maxButtonsToShow;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - maxButtonsToShow + 1;
      if (startPage <= 0) {
        startPage = 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`mx-1 px-3 py-1 rounded-md ${
            i === currentPage
              ? "bg-black text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => handlePageChange(i)}>
          {i}
        </button>,
      );
    }

    if (startPage > 1) {
      buttons.unshift(
        <button key="startEllipsis" className="mx-1 px-3 py-1 rounded-md">
          ...
        </button>,
      );
    }

    if (endPage < totalPages) {
      buttons.push(
        <button key="endEllipsis" className="mx-1 px-3 py-1 rounded-md">
          ...
        </button>,
      );
    }

    return <div>{buttons}</div>;
  };

  const handleLinkClick = (key: any) => {
    const jiraLinks = jiraLink.find(link => link === key);
    if (jiraLinks) {
      setRedirectLink(
        `https://jira.tvac.bt.co.uk/plugins/servlet/com.easesolutions.jira.plugins.requirements/project?detail=TCS&issueKey=${jiraLinks}`,
      );
    }
  };

  return (
    <div className="rounded-lg border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 w-[93.2vw]">
      <h4 className="mb-6 text-xl font-semibold text-black">
        Issues For: Software
      </h4>
      <div className="mb-4">
        <p>Version: Null</p>
        <p>Hub Type: - No Hub</p>
      </div>
      <br />
      {currentItems.length > 0 ? (
        <>
          <div className="flex flex-col border">
            <div className="grid grid-cols-3 rounded-sm bg-gray-100 dark:bg-meta-4 sm:grid-cols-7 overflow-x-auto">
              <div className="p-2.5 xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  TCS ID
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Summary
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Description
                </h5>
              </div>
              <div className="p-2.5 text-center xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Status Category
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Passed
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Tests Failed
                </h5>
              </div>
              <div className="hidden p-2.5 text-center sm:block xl:p-5">
                <h5 className="text-sm font-medium uppercase xsm:text-base">
                  Priority
                </h5>
              </div>
            </div>

            {currentItems.map((brand: any, key) => (
              <div
                className={`grid grid-cols-3 sm:grid-cols-7 ${
                  key === currentItems.length - 1
                    ? ""
                    : "border border-stroke dark:border-strokedark"
                }`}
                key={key}>
                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                  <a
                    href={redirectLink}
                    onClick={() => handleLinkClick(brand.key)}
                    className="text-indigo-600 sm:block">
                    {brand.key}
                  </a>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
                    {brand.summary}
                  </p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p className="truncate hover:overflow-visible hover:whitespace-normal hover:h-auto">
                    {brand.description !== ""
                      ? brand.description
                      : brand.closedDescripiton}
                  </p>
                </div>

                <div className="flex items-center justify-center p-2.5 xl:p-5">
                  <p
                    className={`inline-flex rounded-full bg-opacity-70 py-1 px-3 text-sm font-medium ${
                      brand.statusCategory === "Done"
                        ? "bg-[#219653] text-success"
                        : brand.statusCategory === "failed"
                          ? "bg-[#D34053] text-danger"
                          : "bg-[#FFA70B] text-warning"
                    }`}>
                    {brand.statusCategory}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-black">
                    {summaryData[brand.key]
                      ? summaryData[brand.key].pass_count
                      : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-meta-5">
                    {summaryData[brand.key]
                      ? summaryData[brand.key].fail_count
                      : 0}
                  </p>
                </div>

                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                  <p className="text-meta-5">{brand.priority}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 mb-2">{renderPagination()}</div>
        </>
      ) : (
        <div className="px-6 py-24 text-center" style={{fontWeight: "bold"}}>
          <h1 className="text-bold">No issues found ☹️ !!</h1>
        </div>
      )}
    </div>
  );
};

export default SoftwareTable;

import React, {useEffect, useState} from "react";
import CardDataStats from "../CardData/CardDataStats";
import ArrowUp from "../../assets/icons8-up-arrow-26.png";
import ArrowDown from "../../assets/icons8-down-arrow-26.png";
import TestIcon from "../../assets/icons8-test-100.png";
import Passed from "../../assets/icons8-test-passed-100.png";
import Failed from "../../assets/icons8-scorecard-100.png";
import Skipped from "../../assets/icons8-skip-100.png";
import Null from "../../assets/icons8-null-100.png";
import axios from "axios";
import SoftwareTable from "../../Table/SoftwareTable";

export const CardComponent: React.FC = () => {
  const [apiData, setApiData] = useState({
    total_count: 0,
    pass_count: 0,
    fail_count: 0,
    skipped: 0,
    null: 0,
  });

  const token = sessionStorage.getItem("Access");

  useEffect(() => {
    const headers = {
      Authorization: token,
      Accept: "*/*",
    };

    axios
      .get("https://rwq202prpl.execute-api.eu-west-1.amazonaws.com/sea/tests/summary/9164", {
        headers,
      })
      .then(response => {
        setApiData(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-5 2xl:gap-7.5">
      <CardDataStats
        title="TCS"
        total={apiData.total_count}
        rate={Math.ceil((apiData?.pass_count / apiData?.total_count) * 100)}
        levelUp
        icon={ArrowUp}
        cardIcon={TestIcon}
      />
      <CardDataStats
        title="Passed"
        total={apiData.pass_count}
        rate={Number(
          ((apiData.pass_count / apiData.total_count) * 100).toFixed(2),
        )}
        levelUp
        icon={ArrowUp}
        cardIcon={Passed}
      />
      <CardDataStats
        title="Failed"
        total={apiData.fail_count}
        rate={Number(
          ((apiData.fail_count / apiData.total_count) * 100).toFixed(2),
        )}
        levelUp
        icon={ArrowUp}
        cardIcon={Failed}
      />
      <CardDataStats
        title="Skipped"
        total={apiData.skipped || 0}
        rate={(apiData.skipped / apiData.total_count) * 100 || 0}
        levelDown
        icon={ArrowDown}
        cardIcon={Skipped}
      />
      <CardDataStats
        title="Null"
        total={apiData.null || 0}
        rate={(apiData.null / apiData.total_count) * 100 || 0}
        levelDown
        icon={ArrowDown}
        cardIcon={Null}
      />
    </div>
  );
};

import {useNavigate} from "react-router-dom";

export const useNavigateToDashboard = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToDashboard = () => {
    if (isAuthenticated && currentPath === "/pipelineview/") {
      navigate("/dashboard");
    }
  };

  return {handleNavigateToDashboard};
};

export const useNavigateToPipelineView = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineView = () => {
    if (isAuthenticated && currentPath === "/pipelineview/test-setup/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineView};
};

export const useNavigateToPipelineViewFromSoftware = (
  isAuthenticated: boolean,
  currentPath: string,
) => {
  const navigate = useNavigate();

  const handleNavigateToPipelineViewSoftware = () => {
    if (isAuthenticated && currentPath === "/pipelineview/software/") {
      navigate("/pipelineview/");
    }
  };

  return {handleNavigateToPipelineViewSoftware};
};
